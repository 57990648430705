import { Component, Vue, Prop } from "vue-property-decorator";
import { formatMoney, getSymbolCurrency, formatDate } from "@helpers";
import {
  TransactionStatus,
  TransactionType,
  Transaction,
} from "@/types/transaction";
import { Currency } from "@/types/currency";

@Component
export default class TransactionMixin extends Vue {
  @Prop({ type: String, default: "" }) private readonly search!: string;

  private get appliedSearchByReceptId() {
    return this.search.includes("Recept ID: ");
  }

  protected statusTransaction(status: TransactionStatus): {
    color: string;
    icon: string;
    text: string;
    label?: {
      text: string;
      color: string;
    };
  } {
    let text = this.$vuetify.lang.t(
      `$vuetify.dashboard.transaction.status.${status.toLowerCase()}`
    );

    if (status === TransactionStatus.APPROVED) {
      return {
        text,
        color: "success",
        icon: "$check",
        label: {
          text: this.$vuetify.lang.t(
            "$vuetify.dashboard.transaction.status.settled"
          ),
          color: "success--text",
        },
      };
    }

    if (status === TransactionStatus.PENDING) {
      text = this.$vuetify.lang.t(
        "$vuetify.dashboard.transaction.status.approved"
      );

      return {
        text,
        color: "success",
        icon: "$tablerClock",
        label: {
          text: this.$vuetify.lang.t(
            "$vuetify.dashboard.transaction.status.pending"
          ),
          color: "warning--text",
        },
      };
    }

    return {
      color: "error",
      icon: "$close",
      text,
    };
  }

  protected formatDate(
    val: number,
    options: { showTime?: boolean; showDate?: boolean } = {}
  ): string {
    const { showTime = true, showDate = true } = options;

    return formatDate(val, { showTime, showDate });
  }

  protected formatMoney({
    value,
    currency,
    showSymbol = false,
  }: {
    value: number;
    currency: Currency;
    showSymbol?: boolean;
  }): string {
    return formatMoney({
      value,
      currency,
      showSymbol,
    });
  }

  protected getSymbolCurrency(val: Currency): string {
    return getSymbolCurrency(val);
  }

  private isRefundType(type: TransactionType) {
    return type === TransactionType.REFUND;
  }

  private searchByReceptId(value: Transaction["recieptId"]) {
    if (!value) return;

    this.$emit("update:search", `Recept ID: ${value}`);
  }
}
