import { Currency } from "./currency";

export enum TransactionCardType {
  PREPAID = "PREPAID",
  POSTPAID = "POSTPAID",
}

export enum TransactionStatus {
  DECLINED = "DECLINED",
  APPROVED = "APPROVED",
  // SETTLED = "SETTLED",
  PENDING = "PENDING",
}

export enum TransactionType {
  UNKNOWN = "UNKNOWN",
  AUTHORIZATION = "AUTHORIZATION",
  PAYMENT = "PAYMENT",
  REFUND = "REFUND",
  REFILL = "REFILL",
  COMMISSION = "COMMISSION",
}

export interface Transaction {
  id: number;
  issued_at: string;
  createdAt: string;
  status: TransactionStatus;
  type: TransactionType;
  amount: number;
  currency: Currency;
  description: string;
  recieptId?: string;
  cardNumber: string;
  wallet: string;
  transactionFee: number;
  transactionFixedFee: number;
  cardType: TransactionCardType;
  onHold: boolean;
  refunded?: boolean;
}
