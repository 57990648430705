import {
  Transaction,
  TransactionStatus,
  TransactionType,
  TransactionCardType,
} from "@/types/transaction";
import { Currency } from "@/types/currency";

const mockTransactions: Transaction[] = [
  {
    id: 740256,
    issued_at: "2023-10-11T12:43:06.018Z",
    createdAt: "2023-10-11T12:43:06.018Z",
    status: TransactionStatus.APPROVED,
    type: TransactionType.AUTHORIZATION,
    amount: 0,
    transactionFee: 0,
    transactionFixedFee: 0,
    currency: Currency.USD,
    description: "TABOOLA.COM",
    cardNumber: "5***********5244",
    wallet: "test@test.ru",
    recieptId: "35232325",
    onHold: false,
    cardType: TransactionCardType.PREPAID,
  },
];

export default mockTransactions;
